.root {}

.container {
    display: flex;
    flex-direction: column;
    grid-auto-flow: row;
    align-items: stretch;
    padding: 0px 20px 0px 20px;
    width: 100%;
    margin-top: 25px;
    // margin-right: 0;
    margin-bottom: 0px;
    align-self: center;
}

details summary {
    cursor: pointer;
}

summary {
    padding: 0em 0rem 30px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    transition: height 1s ease;

    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    padding-right: 10px;
    padding-left: 10px;
    /* identical to box height */

    color: #2a2a2a;
    margin: 0px;

    // border-bottom: 0px;
}

summary:after {
    background-image: url('../../../assets/faqExpandIcon.png');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    display: inline-block;
    width: 20px;
    height: 20px;
    content: "";
}

details summary {
    border-bottom: 1px solid #E4E4E4;
    margin-bottom: 30px;
}

details[open] summary {
    border-bottom: 0px;
    margin-bottom: 0px;
    color: #5448B2;
}

details[open] summary:after {
    background-image: url('../../../assets/faqCollapseIcon.png');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    display: inline-block;
    width: 20px;
    height: 20px;
    content: "";
}

details div {
    font-size: 17px;
}

.paginationList {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 14px;
    padding: 6px;
    justify-content: center;
    list-style-type: none;
    gap: 5px;
}

.paginationListItem {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    padding: 0px 7.5px;
    /* identical to box height */
    text-align: center;
    color: #bfb5eb;
}

.paginationListItemActive {

    font-family: 'Lexend';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;

    // position: absolute;
    width: 38px;
    height: 38px;
    border-radius: 50px;

    /* Light */

    background: linear-gradient(180deg, #a1c0ec 0%, #b28dc9 100%);
    padding: 18px;
    margin: 0px;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
}

.answer {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;

    color: #5b5b5b;
    border-bottom: 1px solid #E4E4E4;
    padding-bottom: 37px;
    padding-top: 0px;
    margin: 0px 0px 30px;
    padding-left: 10px;
    padding-right: 10px;
}


.noResultContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 140px;
}

.noResultGraphicDiv {
    margin-top: 110px;
}

.noResultText {
    margin-bottom: 0;
    margin-top: 30px;
    color: #000;
    text-align: center;
    font-family: Lexend;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.noResultSubText {
    width: 760px;
    margin-top: 30px;
    color: #858585;
    text-align: center;
    font-family: Lexend;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.title {
    margin-bottom: 75px;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.paginationListItemDisabled {
    color: #BFB5EB;
    text-align: center;
    font-family: Lexend;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


@media (max-width: 768px) {

    .title {
        color: #000;
        text-align: center;
        font-family: Lexend;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 60px;
        margin-top: 50px;
    }

    .paginationList {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin: 14px 0px 0px 0px;
        padding: 6px;
        justify-content: center;
        list-style-type: none;
        gap: 5px;
    }

    .paginationListAr {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin: 14px 0px 35px 0px;
        padding: 6px;
        justify-content: center;
        list-style-type: none;
        gap: 5px;
    }

    .paginationListItem {
        font-size: 14px;
    }

    .paginationListItemActive {
        font-size: 14px;
    }


    summary {
        font-family: 'Lexend';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;

        padding-right: 10px;
        padding-left: 10px;
        padding-bottom: 16px;

        color: #2A2A2A;
        width: 100%;
    }

    details summary {
        border-bottom: 1px solid #E4E4E4;
        margin-bottom: 21px;
    }

    summary:after {
        background-image: url('../../../assets/faqExpandIcon.png');
        background-repeat: no-repeat;
        margin: 0 0px;
        padding: 0 15px;
    }

    .answer {
        padding-top: 0px;
        margin-top: 0px;
        padding-bottom: 20px;
        margin-bottom: 16px;
        font-family: 'Lexend';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;

        color: #5B5B5B;

    }

    .noResultSubText {
        width: 300px;
        margin-top: 30px;
        color: #858585;
        text-align: center;
        font-family: Lexend;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

}