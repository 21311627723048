@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;500&display=swap');

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.root {
  margin-bottom: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  font-family: 'Lexend';
  min-height: 100vh;
  justify-content: space-around;
  overflow: hidden;
}

.heroImg {
  width: 100%;
  max-width: 140%;
}

.searchBar {
  display: flex;
  align-items: center; 
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  top: -10px;
}

.searchInput {
  border-radius: 30px;
  position: absolute;
  margin: 0;
  padding: 1.8rem 0rem 1.8rem 4rem;
  margin-bottom: 0;
  width: 60%; /* Set the desired width for the search bar */
  max-width: 100%; /* Add this line to ensure the search bar doesn't exceed the viewport width */
  height: 4.5rem;
  top: -120px;
  border: 0 solid black;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  font-family: 'Lexend', 'Fira Sans', 'Droid Sans';
  color: #acacac;
  background: #ffffff url(../../assets/searchIcon.png) no-repeat 30px center;

  &:focus {
    border: 0 solid black;
    outline: 0;
    color: #000000;
  }
}

[dir="rtl"] .searchInput {
  direction: rtl;
  background-position: right 30px center;
  padding: 1.8rem 4rem 1.8rem 0rem;
  font-size: 18px;
}

.title {
  margin-bottom: 75px;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faqSection {
  display: inline-block;
  align-items: center;
  padding: 1em;
  margin: 0;
  width: 90%;
  justify-content: center;
  position: relative;
  align-self: center;
  white-space: pre-wrap;
}

@media (max-width: 768px) {
  .heroImg {
    content: url("../../assets/heroImgSmall.png");
  }

  .heroSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }

  .logoWhite {
    width: 30.9%;
    height: 28.39%;
    position: absolute;
    top: 50%;
    left: 37%;
    transform: translateY(-50%);
  }

  .searchBar {
    top: -40px;
  }

  .searchInput {
    position: absolute;
    margin: 0;
    padding: 1.8rem 0rem 1.8rem 3.5rem;
    width: 89.3%;
    height: 48px;
    top: 0.7em;
    background: #ffffff;
    font-family: 'Lexend', 'Fira Sans', 'Droid Sans';
    color: #acacac;
    border-radius: 25px;
    border: 1px solid rgba(9, 8, 46, 0.2);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
    background: #ffffff url(../../assets/searchIcon.png) no-repeat 20px center;

    &:focus {
      border: 0 solid black;
      outline: 0;
      color: #000000;
    }
  }

  [dir="rtl"] .searchInput {
    direction: rtl;
    background-position: right 30px center;
    padding: 1.8rem 4rem 1.8rem 0rem;
    font-size: 24px;
  }

  .title {
    margin-top: 80px;
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
  }
}