.features-container {
	width: 100%;
	padding: 0px 100px;
}

.featuresTitle {
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	line-height: 72px;
	text-align: center;
	color: rgba(0, 0, 0, 0.87);
}

.contactTitle {
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	line-height: 72px;
	text-align: center;
	color: rgba(0, 0, 0, 0.87);
}

.about-row {
	margin: -69px 0px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.about-containt,
.about-img {
	flex-basis: 50%;
	padding: 20px 20px 20px 0px;
}

.featuresheadding {
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 38px;
	color: rgba(0, 0, 0, 0.87);
	direction: ltr;
	text-align: left;
	margin-bottom: 0;
	display: flex;
	gap: '10px'
}

.featuresheadding p,
h2,
h1,
h6 {
	margin-bottom: 0;
}

.featuresheaddingAr {
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 38px;
	color: rgba(0, 0, 0, 0.87);
	direction: rtl;
	text-align: right;
	margin-bottom: 20px;
}

.featuresubheadding {
	font-style: normal;
	font-weight: 300;
	font-size: 24px;
	line-height: 36px;
	color: rgba(0, 0, 0, 0.87);
	opacity: 0.87;
	direction: ltr;
	text-align: left;
	margin-bottom: 0;
}

.featuresubheaddingAr {
	font-style: normal;
	font-weight: 300;
	font-size: 24px;
	line-height: 36px;
	color: rgba(0, 0, 0, 0.87);
	opacity: 0.87;
	direction: rtl;
	text-align: right;
	min-width: 350px;
}

p {
	margin-top: 0;
	margin-bottom: -1rem;
}

.about-containt h6 {
	margin-top: 45px;
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 28px;
	color: #5d41de;
}

.about-containt p {
	font-style: normal;
	font-weight: 300;
	font-size: 24px;
	line-height: 36px;
	color: rgba(0, 0, 0, 0.87);
}

.about-containtAr {
	font-style: normal;
	font-weight: 300;
	font-size: 24px;
	line-height: 36px;
	color: rgba(0, 0, 0, 0.87);
	direction: rtl;
	text-align: start;
	/* margin-top: -20px; */
}

.mt-3 {
	margin-top: 6rem;
}

.features-section {
	margin-top: 50px;
}

/* .mbl-img {
	animation: transitionIn 3.8s;
} */

.about-img img {
	width: 100%;
}

.paragraphTitle {
	display: flex;
	flex-basis: 50%;
	direction: ltr;
	text-align: left;
	margin-bottom: 0;
}

.paragraphTitleAr {
	display: flex;
	direction: rtl;
	text-align: right;
	margin-bottom: 0;
	width: 100%;
}

.feature1Span {
	width: 25px;
	margin-right: 15px;
}


.indicatorWrapper {
	display: flex;
	text-align: center;
	justify-content: center;
	margin-top: 8px;
	/* margin-bottom: 30px; */
}



@media screen and (max-width: 767px) {

	.indicatorWrapper {
		margin-top: 30px;
	}

	.feature1Span {
		margin-right: -6px;
		/* margin-left: 18px; */
	}

	.about-containtAr {
		font-style: normal;
		font-weight: 300;
		font-size: 24px;
		line-height: 36px;
		color: rgba(0, 0, 0, 0.87);
		direction: rtl;
		text-align: start;
		margin-top: -20px;
	}

	.container {
		margin-top: 10px;
		padding: 15px 10px;
		margin-bottom: 45px;
	}

	.about-row {
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	.featuresheadding {
		color: rgba(0, 0, 0, 0.87);
		font-family: Lexend;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		width: max-content;
	}

	.about-containt p {
		margin-left: 20px;
		width: auto;
	}

	.paragraphWrapper {
		padding: 0;
	}

	.paragraphTitleAr {
		display: flex;
		flex-basis: 50%;
		/* padding: 20px */
		direction: rtl;
		text-align: right;
		column-gap: 17px;
	}

	.paragraphTitle {
		color: rgba(0, 0, 0, 0.87);
		font-family: Lexend;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}


	.featuresheaddingAr {
		color: rgba(0, 0, 0, 0.87);
		text-align: right;
		font-family: Lexend;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		text-align: right;
		direction: rtl;

	}

	.featuresubheaddingAr {
		color: rgba(0, 0, 0, 0.87);
		text-align: right;
		font-family: Tajawal;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 25px;
		/* 208.333% */
		width: 150px;
		min-width: auto;
	}

	.featuresubheadding {
		color: rgba(0, 0, 0, 0.87);
		font-family: Lexend;
		font-size: 12px;
		font-style: normal;
		font-weight: 300;
		line-height: 19px;
		/* 158.333% */
	}


}

@media (min-width: 481px) and (max-width: 500px) {

	.indicatorWrapper {
		margin-top: 30px !important;
	}

	.feature1Span {
		margin-right: -6px;
		/* margin-left: 18px; */
	}

	.about-containtAr {
		font-style: normal;
		font-weight: 300;
		font-size: 24px;
		line-height: 36px;
		color: rgba(0, 0, 0, 0.87);
		direction: rtl;
		text-align: start;
		margin-top: -20px;
	}

	.container {
		margin-top: 10px;
		padding: 15px 10px;
		margin-bottom: 45px;
	}

	.about-row {
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	.featuresheadding {
		color: rgba(0, 0, 0, 0.87);
		font-family: Lexend;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		width: max-content;
	}

	.about-containt p {
		margin-left: 20px;
		width: auto;
	}

	.paragraphWrapper {
		padding: 0;
	}

	.paragraphTitleAr {
		display: flex;
		flex-basis: 50%;
		/* padding: 20px */
		direction: rtl;
		text-align: right;
		column-gap: 17px;
	}

	.paragraphTitle {
		color: rgba(0, 0, 0, 0.87);
		font-family: Lexend;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}


	.featuresheaddingAr {
		color: rgba(0, 0, 0, 0.87);
		text-align: right;
		font-family: Lexend;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		text-align: right;
		direction: rtl;

	}

	.featuresubheaddingAr {
		color: rgba(0, 0, 0, 0.87);
		text-align: right;
		font-family: Tajawal;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 25px;
		/* 208.333% */
		width: 150px;
		min-width: auto;
	}

	.featuresubheadding {
		color: rgba(0, 0, 0, 0.87);
		font-family: Lexend;
		font-size: 12px;
		font-style: normal;
		font-weight: 300;
		line-height: 19px;
		/* 158.333% */
	}


}

@media screen and (max-width: 480px) {

	.indicatorWrapper {
		margin-top: 10px !important;
	}

}


/* @keyframes transitionIn {
	from {
		opacity: 0;
		transform: rotateX(-10deg);
	}

	to {
		opacity: 1;
		transform: rotateX(0);
	}
} */

html[lang='ar'] .flip-icon {
	transform: scaleX(-1);
}

.paragraphWrapper {
	/* padding-left: 0.5rem;
	padding-right: 0.5rem; */
	display: flex;
	flex-direction: column;
}

.FaCircle {
	display: inline-block;
	vertical-align: middle;
}

/* .paragraphTitle {
	display: flex;
	flex-basis: 50%;
	/* padding: 20px; 
direction: ltr;
text-align: left;
margin-bottom: 0;
} */


/* .indicator {
	display: flex;
	text-align: center;
} */