@tailwind base;
@tailwind components;
@tailwind utilities;

.About-container {
	width: 100%;
	padding: 0px 100px;
	margin-top: 2px;
}

.contact-container {
	width: 100%;
	padding: 10px 10px;
}

.About-container1 {
	width: 100%;
	/* padding: 50px 100px; */
	margin-top: -10px;
}

.about-row {
	/* margin: -69px 0px; */
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.about-containt,
.about-img {
	/* margin-right: 20px; */
	flex-basis: 50%;
	padding: 20px;
}

.aboutHeadder {
	font-style: normal;
	font-weight: 600;
	font-size: 35px;
	line-height: 44px;

	color: rgba(0, 0, 0, 0.87);
}

p {
	margin-top: 0;
	margin-bottom: -1rem;
}

.about-containt h6 {
	margin-top: 100px;
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 28px;

	color: #5d41de;
}

.title {
	/*         font-family: 'Lexend'; */
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 28px;
	/* identical to box height */

	color: #5d41de;
}

.aboutSubheadder {
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 36px;
	/* or 150% */

	color: rgba(0, 0, 0, 0.87);
	margin-left: 0;
}

.break {
	word-wrap: break-word;
}

.google-adds p {
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 36px;
	/* or 150% */
	word-wrap: break-word;

	color: rgba(0, 0, 0, 0.87);
}

.mt-3 {
	margin-top: 6rem !important;
}

.mbl-img {
	display: inline-block;
}

.mbl-img-animation {
	animation: transitionIn 3.8s;
	display: inline-block;
}

.about-img img {
	width: 100%;
	margin: 0;
}

.mainContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.imagePlusDescription {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 75px;
	margin-bottom: 50px;
}

.mobileImgCol {
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.mobileImgColAr {
	display: flex;
	align-items: flex-start;
	justify-content: center;
}


@media (max-width: 500px) {
	.About-container {
		/* padding: 0px 0px; */
		margin-top: 7px;
		margin-bottom: -160px;
		/* margin-right: 20px; */
		width: 100%;
	}

	.about-row {
		flex-direction: column;
		align-items: flex-start;
		text-align: center;
	}

	.mainContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	.imagePlusDescription {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 20px;
	}

	.mobileImgCol {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		margin-right: -100px;
	}

	.mobileImgColAr {
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		margin-right: -197px;
	}

}

@media (max-width: 600px) {



	.About-container {
		padding: 20px 20px;
		margin-top: 7px;
		margin-bottom: -160px;
	}

	.about-row {
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
}

@media (max-width: 768px) {



	/* For mobile phones: */
	.About-container {
		padding: 0px 0px;
		margin-top: 7px;
		margin-bottom: -160px;
	}

	.about-row {
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
}

@keyframes transitionIn {
	from {
		opacity: 0;
		transform: rotateX(-10deg);
	}

	to {
		opacity: 1;
		transform: rotateX(0);
	}
}

.line {
	padding-bottom: 7px;
	border-bottom-style: solid;
	border-bottom-width: 3.1px;
	width: fit-content;
}

/* .aboutbg{
    background: url(../../../public/img/Background.png);
}
 */