:root {
  /*
  --swiper-pagination-color: var(--swiper-theme-color);
  */
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;

  &.swiper-pagination-hidden {
    opacity: 0;
  }
}

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal>.swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}

/* Bullets */
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;

  .swiper-pagination-bullet {
    transform: scale(0.33);
    position: relative;
  }

  .swiper-pagination-bullet-active {
    transform: scale(1);
  }

  .swiper-pagination-bullet-active-main {
    transform: scale(1);
  }

  .swiper-pagination-bullet-active-prev {
    transform: scale(0.66);
  }

  .swiper-pagination-bullet-active-prev-prev {
    transform: scale(0.33);
  }

  .swiper-pagination-bullet-active-next {
    transform: scale(0.66);
  }

  .swiper-pagination-bullet-active-next-next {
    transform: scale(0.33);
  }
}


.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;

  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #BFB5EB !important;
  opacity: 1.2 !important;
  border-radius: 10px !important;

  @at-root button#{&} {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none;
  }

  .swiper-pagination-clickable & {
    cursor: pointer;
  }

  &:only-child {
    display: none !important;
  }
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: linear-gradient(169.02deg, #B2EBF2 -58.33%, #D1C4E9 60.57%) !important;
  width: 33px;
}

.swiper-container-vertical {
  >.swiper-pagination-bullets {
    right: 10px;
    top: 50%;
    transform: translate3d(0px, -50%, 0);

    .swiper-pagination-bullet {
      margin: 6px 0;
      display: block;
    }

    &.swiper-pagination-bullets-dynamic {
      top: 50%;
      transform: translateY(-50%);
      width: 8px;

      .swiper-pagination-bullet {
        display: inline-block;
        transition: 200ms transform, 200ms top;
      }
    }
  }
}

.swiper-container-horizontal {
  >.swiper-pagination-bullets {
    .swiper-pagination-bullet {
      margin: 0 4px;
    }

    &.swiper-pagination-bullets-dynamic {
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;

      .swiper-pagination-bullet {
        transition: 200ms transform, 200ms left;
      }
    }
  }

  &.swiper-container-rtl>.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: 200ms transform, 200ms right;
  }
}

/* Progress */
.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;

  .swiper-pagination-progressbar-fill {
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transform-origin: left top;
  }

  .swiper-container-rtl & .swiper-pagination-progressbar-fill {
    transform-origin: right top;
  }

  .swiper-container-horizontal>&,
  .swiper-container-vertical>&.swiper-pagination-progressbar-opposite {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0;
  }

  .swiper-container-vertical>&,
  .swiper-container-horizontal>&.swiper-pagination-progressbar-opposite {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.swiper-pagination-lock {
  display: none;
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding-bottom: 80px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-top: 45px !important;


  z-index: 1;
}

@media screen and (max-width: 768px) {
  .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding-bottom: 30px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 40px !important;

    z-index: 1;
  }

  .swiper-pagination-bullet {
    width: 6px !important;
    height: 6px !important;
    display: inline-block;
    border-radius: 50%;


    display: inline-block;
    border-radius: 50%;
    border: 1px solid #BFB5EB !important;
    opacity: 1.2 !important;
    border-radius: 10px !important;


  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: linear-gradient(169.02deg, #B2EBF2 -58.33%, #D1C4E9 60.57%) !important;
    width: 20px !important;
  }
}


@media screen and (max-width: 480px) {
  .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding-bottom: 30px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 40px !important;

    z-index: 1;
  }

  .swiper-pagination-bullet {
    width: 6px !important;
    height: 6px !important;
    display: inline-block;
    border-radius: 50%;

    display: inline-block;
    border-radius: 50%;
    border: 1px solid #BFB5EB !important;
    opacity: 1.2 !important;
    border-radius: 10px !important;


  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: linear-gradient(169.02deg, #B2EBF2 -58.33%, #D1C4E9 60.57%) !important;
    width: 20px !important;
  }
}

@media (min-width: 1601px) and (max-width: 2000px) {
  .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding-bottom: 123px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 50px !important;
  }

  #Discover {

    background-image: url(../../../public/img/Mask1.png);

    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0px;

  }

  .discover-section {
    margin-top: 250px;
  }

}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: linear-gradient(169.02deg, #B2EBF2 -58.33%, #D1C4E9 60.57%) !important;
  width: 20px !important;
}