@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('//fonts.googleapis.com/css?family=Lexend');
@import url('//fonts.googleapis.com/css?family=Tajawal');

html {
	overflow-x: hidden;
}

html[lang='ar'] * {
	font-family: 'Tajawal';
	overflow-x: hidden;
}

.nav-link {
	color: #fff;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0 1rem;
	height: 100%;
	cursor: pointer;
	font-size: 17px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0em;
	text-align: center;
}

.nav-link:hover {
	color: #9f95dc;
	/* Add any additional hover styles you want */
}

.input-feedback {
	font-size: 14px;
	line-height: 1rem;
	--tw-text-opacity: 1;
	text-align: justify;
	padding-bottom: 9px;
	margin-top: -16px;
	color: red;
}

.message-success {
	font-size: 18px;
	text-align: center;
	padding: 24px;
	background: #5448b2;
	color: white;
}

.d-grid {
	display: grid !important;
	height: calc(100% - 170px);
	grid-template-columns: repeat(2, 1fr);
	padding: 0 12.5% 0 12.5%;
	background: #5c97e6;
	background: -webkit-linear-gradient(top left, #5c97e6, #4069a1);
	background: -webkit-gradient(linear, left top, right bottom, from(#5c97e6), to(#4069a1));
	background: -o-linear-gradient(top left, #5c97e6, #4069a1);
	background: linear-gradient(to bottom right, #5c97e6, #4069a1);
}

.grid-column {
	padding-top: 50px;
}

.align-items-center {
	-ms-flex-align: center !important;
	align-items: center !important;
}

.btn-link {
	font-weight: 400;
	color: white;
	text-decoration: underline;
}

.btn-link :hover {
	color: white;
}

.langSelector {
	position: relative;
	margin-top: 0px;
	margin-left: 30px;
	font-family: 'Lexend';
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 150%;

	display: flex;
	align-items: center;
	text-align: center;
	font-feature-settings: 'salt' on, 'liga' off;

	color: rgba(255, 255, 255, 0.85);
	background: transparent;
	border: none;
}

.langSelector.show {
	position: relative;
	margin-top: 0px;

	font-family: 'Lexend';
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 150%;
	/* identical to box height, or 26px */

	display: flex;
	align-items: center;
	text-align: center;
	font-feature-settings: 'salt' on, 'liga' off;

	color: rgba(255, 255, 255, 0.85);
	background: transparent;
	border: none;

	margin-bottom: 40px;
}

.dropdown-menu {
	display: none;
	border: none;
	left: 5%;
	/* position */
	position: relative;
	top: 20px;
	font-family: 'Lexend';
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 150%;
	align-items: center;
	font-feature-settings: 'salt' on, 'liga' off;

	color: rgba(0, 0, 0, 0.85);
}

.dropdown-menu::before {
	display: flex;
	border: none;
	content: '';
	left: 5%;
	position: absolute;
	top: -20px;
	font-family: 'Lexend';
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 150%;
	background: #fff;
	margin-bottom: 40px;
}

.dropdown-menu::after {
	border: none;
	content: '';
	display: flex;
	left: 5%;
	position: absolute;
	top: -20px;
	font-family: 'Lexend';
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 150%;

}


a .dropdown-item {
	color: var(--default-white, #FFF);
	font-family: Lexend;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	margin-bottom: 16px;
	/* 24px */
}

.overlay2 {
	position: inherit;
	width: 100vw;
	/* height: 100vh; */
	/* background-color: rgba(0, 0, 0, 0.761); */
	/* z-index: 9999999; */
	display: block;
}

ul,
ol {
	padding: 0;
	/*  margin: 0 0 0px 0px; */
}

a:hover {
	text-decoration: none;
}

.text-small {
	font-size: 0.75rem;
	line-height: 1rem;
	color: white;
	text-align: initial;
	/* margin-top: -75px; */
	/* padding: 33px; */

	margin: -25px 0px 3px 30px;
}

.playstore {
	margin-top: 1rem !important;
}

.text-2xl {
	font-size: 24px;
	line-height: 1.75rem;
	/* 28px */
	margin-top: -6px;
	color: white;
	margin: 0px -50px 0px -5px;
}

.market-btn {
	border: 1px solid #e7e7e7;
	cursor: pointer;
	background-repeat: no-repeat;
	background-size: 56px 60px;
	margin-top: 12px;
	padding: 31px;
}

.market-btn .market-button-title {
	display: block;
	color: #fff;
	font-size: 27px;
	text-align: center;
	/* padding-left: 20px; */
	/* padding-right: 2px; */
	/* padding-top: 0px; */
	margin-right: -26px;
	margin-left: 1px;
}

.market-btn .market-button-subtitle {
	display: block;
	margin-bottom: -0.25rem;
	color: #fff;
	font-size: 0.75rem;
	margin-left: 51px;
	margin-right: 0px;
	margin-top: 4px;
}

.market-btn:hover {
	background-color: #000;
	text-decoration: none;
}

.apple-btn {
	background-image: url(../public/img/Apple.png);
	height: 60px;
	width: 200px;
	margin-right: 20px;
	border-radius: 8px;
	background-color: black;
	border-radius: 8px;
}

.google-btn {
	background-image: url(../public/img/google-play.png);
	height: 60px;
	width: 200px;

	background-color: black;
	border-radius: 8px;
	margin-right: 20px;
}

.market-btn-light {
	border-color: rgba(255, 255, 255, 0.14);
	background-color: rgba(0, 0, 0, 0);
}

.market-btn-light .market-button-title {
	color: #fff;
}

.market-btn-light .market-button-subtitle {
	color: white;
}

.market-btn-light:hover {
	background-color: rgba(255, 255, 255, 0.06);
}

.a button .market-btn .google-btn :hover {
	color: #5c97e6 !important;
	cursor: pointer !important;
}

.dropdown-toggle:after {
	content: none;
}

.contact-section {
	margin-top: 60px !important;
	padding: 0px !important;
}

.Group3 {
	/* margin-top: 50px; */
}

.feedback {
	color: red;
	text-align: justify;
	margin-top: -31px;
}

.scroll {
	max-height: 400px;
	overflow-y: scroll;
}

.myvideo {
	width: 100%;
	height: 100%;
}

#ContactUs {
	background-image: url(../public/img/contactus.png) !important;
	background-size: cover !important;
	background-repeat: no-repeat;
}

.discover-img {
	height: 242px !important;
	width: 259px !important;
}

.terms-para p {
	font-size: 18px;
	margin: 0 auto;
	color: #000;
}

.policy-address p {
	font-size: 18px;
	margin: 0 auto;
	color: #000;

	margin-left: 80px;
}

.formCol {
	padding: 40px 80px;
}

@media screen and (max-width: 769px) {
	.swiper-container {
		margin-left: auto;
		margin-right: auto;
		position: relative;
		overflow: hidden;
		list-style: none;
		padding-bottom: 80px !important;
		padding-right: 120px !important;
		padding-left: 120px !important;

		padding-top: 0px !important;

		z-index: 1;
	}
}

@media screen and (max-width: 768px) {

	.dropdown-menu {
		background-color: transparent;
		padding-top: 16px;
	}

	.formCol {
		padding: 22px 40px;
	}

	.formColAr {
		padding: 31px 35.8px 0px 42px;
	}

	/* Hero */
	.google-btn {
		background-image: url(../public/img/google-play.png);
		height: 35px;
		width: 110px;

		background-color: black;
		border-radius: 4px;
	}

	.apple-btn {
		background-image: url(../public/img/Apple.png);
		height: 35px;
		width: 110px;
		margin-right: 20px;
		background-color: black;
		border-radius: 4px;
		margin-left: 20px;
	}

	.market-btn .market-button-title {
		display: block;
		color: #fff;
		font-size: 18px;

		text-align: center;
	}

	.text-2xl {
		font-size: 9px;
		line-height: 1.75rem;
		margin-top: -6px;
		color: white;
		margin: -11px 9px 0px -1px;
	}

	.text-small {
		font-size: 8px;
		line-height: 1rem;
		color: white;
		text-align: initial;
		/* margin-top: -75px; */
		/* padding: 33px; */

		margin: -4px 0px 3px 2px;
	}

	.market-btn .market-button-subtitle {
		display: block;
		margin-bottom: -0.25rem;
		color: #fff;
		font-size: 0.75rem;
	}

	.market-btn {
		padding: 0.3125rem;
		padding-left: 2.8125rem;

		border: 1px solid #e7e7e7;
		cursor: pointer;
		background-size: 2rem 2rem;
		background-repeat: no-repeat;
	}

	/* about us */
	.Vector2 {
		position: relative;
		height: 70.41976165771484px;
		width: 63.31886291503906px;
		text-align: center;
		left: 41.574219px;
		top: 145px;
		right: 210px;
	}

	.diamond3 {
		text-align: center;
		height: 42.91519546508789px;
		width: 41.85693359375px;
		left: 73.870117px;
		top: 65.594757px;
		border-radius: 0px;
	}

	.Group3 {
		height: 23.936405181884766px;
		width: 56.10686111450195px;
		left: 31.893555px;
		top: 34.582153px;
		border-radius: 0px;
		position: relative;
		right: 26px;
	}

	.mbl-img {
		height: 206px !important;
		width: 93px;
		right: 29px;
		top: 0px;
		border-radius: 0px;
		position: relative;
		left: 20px;
	}

	.about-containt h6 {
		font-size: 14px !important;
		line-height: 18px !important;
		margin-bottom: 10px !important;
	}

	.aboutHeadder {
		font-size: 17.5px !important;
		line-height: 22px !important;
		margin-bottom: 10px !important;
	}

	.aboutSubheadder {
		font-size: 12px !important;
		line-height: 18px !important;
		margin-bottom: 10px !important;
		margin-left: 0px !important;
	}

	.aboutSubheadderAr {
		font-size: 12px !important;
		line-height: 18px !important;
		margin-bottom: 10px !important;
		margin-left: 25px !important;
	}

	.diamond2 {
		height: 63.15166473388672px;
		width: 55.094459533691406px;
		left: 69px;
		top: -73.32843px;
		border-radius: 0px;
		right: 26.97%;
		position: relative;
	}

	.about-section {
		padding-left: 0rem !important;
		margin-left: 0px;
		padding-right: 20px !important;
		padding-top: 20px;
	}

	.Group2 {
		height: 33.141563415527344px;
		width: 63.367733001708984px;
		left: -25.171875px !important;
		top: 168.437744px;
		border-radius: 0px;
		position: relative;
		right: -5.03%;
	}

	/* Features */

	.featuresTitle {
		font-size: 24px !important;
		line-height: 72px !important;
		margin-top: 40px;
	}

	.contactTitle {
		font-size: 24px !important;
		line-height: 72px !important;
		margin-top: 105px;
	}

	.indicator {
		height: 6px;
		width: 35.75px;
		margin-top: -45px;
	}

	.features-section {
		margin-top: 0px !important;
	}

	.featuresheadding {
		font-size: 14px !important;
		line-height: 18px !important;

		margin-top: 4px;
		margin-bottom: -8px !important;
		padding: 0px;
	}

	.featuresubheadding {
		font-size: 12px !important;
		line-height: 19px !important;
		margin-top: 22px !important;
	}

	.featuresubheaddingAr {
		margin-top: 10px !important;
		color: rgba(0, 0, 0, 0.87);
		text-align: right !important;
		font-family: Tajawal !important;
		font-size: 12px !important;
		font-style: normal !important;
		font-weight: 500 !important;
		line-height: 19px !important;
		/* 208.333% */
	}


	.about-img {
		height: 141.7860107421875px;
		width: 157.1076202392578px;
		/* padding: 3px !important; */
		position: relative;
		padding-left: 0px !important;
	}

	.features-container {
		width: 100%;
		padding: 16px 0px !important;
	}

	.about-containt,
	.about-img {
		flex-basis: 50%;
		padding: 0px !important;
	}

	.FaCircle {
		height: 8px;
		width: 8px;
	}

	.FaCirclle {
		height: 8px;
		width: 8px;
		margin-left: 3px !important;
		margin-right: 1px !important;
		/* float: right !important; */
		margin-top: 5px;
	}

	.About-container {
		padding: 0px 0px;
		margin-top: 7px;
	}

	/* discover */
	.discover-section {
		margin-top: 2px !important;
		margin-bottom: -85px !important;
	}

	.discover-img {
		height: 97px !important;
		width: 130px !important;
	}

	.card-text {
		font-size: 10px !important;
		font-weight: 300 !important;
		line-height: 20px !important;
		margin-top: -20px;

		padding-left: 1px !important;
		padding-right: 1px !important;
		padding-bottom: 5px !important;
	}



	.swiper-container {
		margin-left: auto;
		margin-right: auto;
		position: relative;
		overflow: hidden;
		list-style: none;
		padding-bottom: 0px;
		padding-right: 0px;
		padding-left: 0px;
		padding-top: 0px;
		z-index: 1;
	}

	.card-header {
		padding: 0.5rem 1rem;
		background-color: #fff;
		border-bottom: 0px solid rgba(0, 0, 0, 0.125);
		border-radius: 40px 40px 40px 40px !important;
	}

	.card {
		position: relative;
		display: flex;
		flex-direction: column;
		min-width: 0;
		word-wrap: break-word;
		background-color: #fff;
		background-clip: border-box;
		margin: 30px 0px 0px 0px !important;
		border-radius: 0.25rem;
		width: auto;
		border: none;
		border-radius: 20px 20px 20px 20px !important;
		box-shadow: 0px 10px 10px rgb(0 0 0 / 5%);
	}

	#Discover {
		background-image: url(../public/img/Mask2.png) !important;
		background-size: contain !important;
		background-repeat: no-repeat;

		border-radius: 0px;
	}


	/*contactus */
	.contact-container {
		width: 100%;
		padding-right: 0px;
		padding: 1px 30px !important;
		margin-top: 100px;
	}


	.subhead {
		font-size: 12px !important;

		line-height: 15px !important;
		margin-bottom: 10px !important;
	}

	.contactdes {
		font-size: 10px !important;

		line-height: 13px !important;
		padding: 2px;
	}

	.contactusfooter {
		font-size: 30px !important;
		font-weight: 700;
		line-height: 40px !important;
		margin: 150px 0px 0px 0px !important;

		padding-left: 40px !important;
		padding-right: 40px !important;
	}

	#ContactUs {
		background-image: 100px url(../public/img/contactus1.png) !important;
		background-size: cover !important;
		background-repeat: no-repeat;

	}

	.contact-section {
		margin-top: 0px !important;
		padding: 0px !important;
	}

	.feedback {
		color: red;
		text-align: justify;
		margin-top: -17px;
		font-size: 12px;
	}

	/*footer*/
	.footersub {
		font-size: 10px !important;

		line-height: 24px !important;
	}

	.footlogo {
		height: 20px;

		border-radius: 4px;
		margin-bottom: 10px;
	}

	.footerhead {
		font-size: 12px !important;

		line-height: 15px !important;
	}

	.atcopyright {
		font-size: 12px !important;
		font-weight: 400;
		line-height: 17px !important;
	}

	/*terms*/
	.terms-para p {
		font-size: 16px;
		margin: 0 auto;
		color: #000;
	}

	.policy-address p {
		font-size: 16px;
		margin: 0 auto;
		color: #000;
		margin-left: 80px;
	}

	.bg {
		background-image: url(../public/img/Decoration1.png) !important;
		background-size: contain;
		background-repeat: no-repeat;
		background-size: cover;
	}
}

@media screen and (max-width: 480px) {

	/* Hero */
	.dropdown-menu {
		display: none;
		border: none;
		left: 5%;
		/* position */
		position: relative;
		top: 20px;
		font-family: 'Lexend';
		font-style: normal;
		font-weight: 400;
		font-size: 17px;
		line-height: 150%;
		align-items: center;
		font-feature-settings: 'salt' on, 'liga' off;
		background: transparent;

		color: #fff;
	}

	.dropdown-menu::before {
		display: flex;
		border: none;
		content: '';
		left: 5%;
		position: absolute;
		top: -20px;
		font-family: 'Lexend';
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 150%;
	}

	.dropdown-menu::after {
		border: none;
		content: '';
		display: flex;
		left: 5%;
		position: absolute;
		top: -20px;
		font-family: 'Lexend';
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 150%;
	}

	.google-btn {
		background-image: url(../public/img/google-play.png);
		height: 35px;
		width: 110px;

		background-color: black;
		border-radius: 4px;
	}

	.apple-btn {
		background-image: url(../public/img/Apple.png);
		height: 35px;
		width: 110px;
		margin-right: 20px;
		background-color: black;
		border-radius: 4px;
		margin-left: 20px;
	}

	.market-btn .market-button-title {
		display: block;
		color: #fff;
		font-size: 13px;
		text-align: center;
	}

	.text-2xl {
		font-size: 11px;
		line-height: 1.75rem;
		margin-top: -6px;
		color: white;
		margin: -11px 9px 0px -1px;
	}

	.text-small {
		font-size: 8px;
		line-height: 1rem;
		color: white;
		text-align: initial;
		/* margin-top: -75px; */
		/* padding: 33px; */

		margin: -4px 0px 3px 2px;
	}

	.market-btn .market-button-subtitle {
		display: block;
		margin-bottom: -0.25rem;
		color: #fff;
		font-size: 8px;
	}

	.market-btn {
		padding-left: 31px;
		border: 1px solid #e7e7e7;
		cursor: pointer;
		background-size: 2rem 2rem;
		background-repeat: no-repeat;
		padding-right: 1px;
	}

	/* about us */
	.Vector2 {
		position: relative;
		height: 70.41976165771484px;
		width: 63.31886291503906px;
		text-align: center;
		left: 41.574219px;
		top: 145px;
		right: 210px;
	}

	.diamond3 {
		text-align: center;
		height: 42.91519546508789px;
		width: 41.85693359375px;
		left: 64.870117px;
		top: 65.594757px;
		border-radius: 0px;
	}

	.Group3 {
		height: 23.936405181884766px;
		width: 56.10686111450195px;
		left: 31.893555px;
		top: 34.582153px;
		border-radius: 0px;
		position: relative;
		right: 26px;
	}

	.mbl-img {
		height: 206px !important;
		width: 93px;
		right: 29px;
		top: -20px;
		border-radius: 0px;
		position: relative;
		left: 20px;
	}

	.about-containt h6 {
		font-size: 14px !important;
		line-height: 18px !important;
		margin-bottom: 10px !important;
	}

	.aboutHeadder {
		font-size: 17.5px !important;
		line-height: 22px !important;
		margin-bottom: 10px !important;
	}

	.aboutSubheadder {
		font-size: 12px !important;
		line-height: 18px !important;
		margin-bottom: 10px !important;
	}

	.diamond2 {
		height: 63.15166473388672px;
		width: 55.094459533691406px;
		left: 69px;
		top: -73.32843px;
		border-radius: 0px;
		right: 26.97%;
		position: relative;
	}

	.about-section {
		padding-left: 0rem !important;
		margin-left: 0px;
		padding-right: 20px !important;
		padding-top: 20px;
	}

	.Group2 {
		height: 33.141563415527344px;
		width: 63.367733001708984px;
		left: -25.171875px !important;
		top: 168.437744px;
		border-radius: 0px;
		position: relative;
		right: -5.03%;
	}

	/* Features */

	.featuresTitle {
		font-size: 24px;
		line-height: 72px;
		margin-top: 40px;
	}

	.contactTitle {
		font-size: 24px !important;
		line-height: 72px !important;
		margin-top: 105px;
	}

	.indicator {
		height: 6px;
		width: 35.75px;
		margin-top: -22px;
	}

	.features-section {
		margin-top: 0px !important;
	}

	.featuresheadding {
		font-size: 14px !important;
		line-height: 18px !important;

		margin-top: 4px;
		margin-bottom: -8px !important;
		padding: 0px;
	}

	.featuresubheadding {
		font-size: 12px !important;
		line-height: 19px !important;
		margin-top: 22px !important;
	}

	.about-img {
		height: 141.7860107421875px;
		width: 157.1076202392578px;
		/* padding: 3px !important; */
		position: relative;
		padding-left: 0px !important;
		margin-left: -15px;
	}

	.features-container {
		width: 100%;
		padding: 16px 0px !important;
	}

	.about-containt,
	.about-img {
		flex-basis: 50%;
	}

	.FaCircle {
		height: 8px;
		width: 8px;
	}

	.FaCirclle {
		height: 8px;
		width: 8px;
		margin-left: 3px !important;
		margin-right: 1px !important;
		/* float: right !important; */
		margin-top: 5px;
	}

	.About-container {
		padding: 0px 0px;
		margin-top: 7px;
	}

	/* discover */
	.discover-section {
		margin-top: 2px !important;
		margin-bottom: -85px !important;
	}

	.discover-img {
		height: 97px !important;
		width: 130px !important;
	}

	.card-text {
		font-size: 10px !important;
		font-weight: 300 !important;
		line-height: 20px !important;
		margin-top: -20px;

		padding-left: 1px !important;
		padding-right: 1px !important;
		padding-bottom: 5px !important;
	}

	.card-title {
		margin-bottom: 0.5rem;

		font-style: normal;
		font-weight: 500;
		font-size: 12px !important;
		line-height: 36px;
		text-align: center;
		margin-top: -28px !important;
	}

	.swiper-container {
		margin-left: auto;
		margin-right: auto;
		position: relative;
		overflow: hidden;
		list-style: none;
		padding-bottom: 0px;
		padding-right: 0px;
		padding-left: 0px;
		padding-top: 0px;
		z-index: 1;
	}

	.card {
		position: relative;
		display: flex;
		flex-direction: column;
		min-width: 0;
		word-wrap: break-word;
		background-color: #fff;
		background-clip: border-box;
		margin: 30px 0px 0px 0px !important;
		border-radius: 0.25rem;
		width: auto;
		border: none;
		border-radius: 20px 20px 20px 20px !important;
		box-shadow: 0px 10px 10px rgb(0 0 0 / 5%);
	}

	.card-img {
		height: 130px;
	}

	#Discover {
		background-image: url(../public/img/Mask2.png) !important;
		background-size: contain !important;
		background-repeat: no-repeat;

		border-radius: 0px;
	}


	/*contact us */
	.contact-container {
		width: 100%;
		padding: 1px 30px !important;
	}

	.subhead {
		font-size: 12px !important;

		line-height: 15px !important;
		margin-bottom: 10px !important;
	}

	.contactdes {
		font-size: 10px !important;

		line-height: 13px !important;
		padding: 2px;
	}

	.contactusfooter {
		font-size: 20px !important;
		font-weight: 700;
		line-height: 30px !important;
		margin: 150px 0px 0px 0px !important;

		padding-left: 40px !important;
		padding-right: 40px !important;
	}

	#ContactUs {
		background-image: url(../public/img/contactus1.png) !important;
		background-size: cover !important;
		background-repeat: no-repeat;
	}

	.contact-section {
		margin-top: 0px !important;
		padding: 0px 0px !important;
	}

	.feedback {
		color: red;
		text-align: justify;
		margin-top: -17px;
		font-size: 12px;
	}

	/*footer*/
	.footersub {
		font-size: 10px !important;

		line-height: 24px !important;
	}

	.footlogo {
		height: 20px;

		margin-bottom: 10px;
		border-radius: 4px;
	}

	.footerhead {
		font-size: 12px !important;

		line-height: 15px !important;
	}

	.atcopyright {
		font-size: 12px !important;
		font-weight: 400;
		line-height: 17px !important;
	}

	/*terms*/
	.terms-para p {
		font-size: 16px;
		margin: 0 auto;
		color: #000;
	}

	.policy-address p {
		font-size: 16px;
		margin: 0 auto;
		color: #000;
		margin-left: 80px;
	}

	.bg {
		background-image: url(../public/img/Decoration1.png) !important;
		background-size: contain;
		background-repeat: no-repeat;
		background-size: cover;
	}
}

.rotate {
	transform: rotate(180deg);
	/* Rotate and translate to the right */
	transform-origin: center center;
	padding: 0;
	margin: 0;
	width: 16px;
	height: 9px;
}

.notRotated {
	padding: 0;
	margin: 0;
	width: 16px;
	height: 9px;
}