/* PrivacyPolicy.css */

/* Force LTR direction for the PrivacyPolicy component */
.terms-ltr {
  direction: ltr !important;
  text-align: left !important;
}


.topBanner {
  width: 100%;
  height: 85px;
  background: #E8E8F8;
  padding: 24px 0 17px 0;

}

.container {
  margin-top: 75px;
}

.header {
  color: #000;
  font-feature-settings: 'salt' on, 'liga' off;
  font-family: Lexend;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 33px */

  margin-bottom: 40px;
}

.paragraphsText {
  color: var(--default-body, #222);

  /* Base/Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  margin-top: 35px;
  margin-bottom: 35px;
}