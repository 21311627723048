@media (max-width: 500px) {
	.Discover-container {
		padding: 20px 20px;
	}

	.about-row {
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
}

.mt-3 {
	margin-top: 130px !important;
}

.discover-section {
	margin-top: 150px;
}

#Discover {
	background-image: url(../../../public/img/Mask1.png);

	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 0px;
}

.Discover-container {
	width: 100%;
}


/* .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: -6px !important;
    left: 0;
    width: 100%;
    z-index: -1 !important;
    position: relative !important;
}
 */

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
	bottom: -5px !important;
	left: 0;
	width: 100%;

	position: fixed !important;
}

.swiper-container-3d .swiper-slide-shadow-right {
	background: transparent !important;
}

.swiper-container-3d .swiper-slide-shadow-left {
	background: transparent !important;
}

.testimonial-container {
	/*   width: 100%;
    background-image: url(../../../public/img/Mask2.png);
   
    background-size: contain;
    background-repeat: no-repeat;
        background-size: cover;
        text-align:center; */
}

.testimonial-container h1 {
	text-align: center;
	margin-top: 271px;
}

.testimonial-row {
	width: 100%;
	margin-top: 30px;
	display: flex;
	justify-content: space-evenly;
	overflow-x: scroll;
	margin-top: 180px;
}

.testimonial-row::-webkit-scrollbar {
	display: none;
}

.testimonial-row .testimonial-card {
	border-radius: 40px;

	text-align: center;
	margin: 10px;
	padding: 20px;
	border-radius: 10px;
	background: #ffffff;
	box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
	border-radius: 40px 40px 40px 40px;
	cursor: pointer;
	color: black;
}

p {
	font-size: 18px;
	color: #989eb9;
	text-justify: inter-word;
}

@media (max-width: 500px) {

	.card {
		position: relative;
		display: flex;
		flex-direction: column;
		min-width: 0;
		word-wrap: break-word;
		background-color: #fff;
		background-clip: border-box;
		margin: 60px 45px 0px 45px;
		border-radius: 0.25rem;
		width: auto;
		border: none;

		border-radius: 40px 40px 40px 40px;

		box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);

		height: 300px;
	}

	.card-title {
		text-align: center;
		font-family: Lexend;
		font-size: 14px !important;
		font-style: normal;
		font-weight: 500;
		line-height: 4px;
		/* 300% */
	}

	.card-text {
		color: rgba(0, 0, 0, 0.87);
		text-align: center;
		font-family: Lexend;
		font-size: 10px !important;
		font-style: normal;
		font-weight: 300;
		line-height: 25px;
		/* 250% */
		padding: 10px !important;
	}

	.testimonial-container {
		padding: 0px;
	}

	.swiper-slide {
		position: relative;
		display: flex;
		flex-direction: column;
		min-width: 0;
		word-wrap: break-word;

		background-clip: border-box;

		border-radius: 0.25rem;
		width: auto;

		border-radius: 40px 40px 40px 40px;
	}
}

.card-header {
	display: flex;
	padding: 0.5rem 1rem;
	height: 50%;
	background-color: #fff;
	border-bottom: 0px solid rgba(0, 0, 0, 0.125);
	border-radius: 40px 40px 40px 40px !important;
	justify-content: center;
	align-items: center;
}


.card-title {
	margin-bottom: 0.5rem;
	/*     font-family: 'Lexend'; */
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 20px;
	/* or 164% */

	text-align: center;
}



@media (min-width: 1601px) and (max-width: 2000px) {

	.swiper-slide {
		position: relative;
		display: flex;
		flex-direction: column;
		min-width: 0;
		word-wrap: break-word;

		background-clip: border-box;

		border-radius: 0.25rem;
		width: auto;

		border-radius: 40px 40px 40px 40px;
	}
}

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	margin: 60px 45px 0px 45px;
	border-radius: 0.25rem;
	width: auto;
	border: none;

	border-radius: 40px 40px 40px 40px;

	box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);

	height: 375px;
	min-width: 300px;
}

.carousel-control-next-icon {
	display: none;
}

.carousel-control-prev-icon {
	display: none;
}

#ContactUs {
	background-image: url(../../../public/img/contactus.png);
	background-size: contain;
	background-repeat: no-repeat;
}

.card-text {
	/*     font-family: 'Lexend'; */
	font-style: normal;
	font-weight: 300;
	font-size: 13px;
	line-height: 30px;
	/* or 200% */

	text-align: center;

	color: rgba(0, 0, 0, 0.87);
}

.subhead {
	/* font-family: 'Lexend'; */
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;

	text-align: center;

	color: #2a2a2a;
}

.contactdes {
	/*    font-family: 'Lexend'; */
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 22px;
	text-align: center;

	color: #2a2a2a;
}

.contactusfooter {
	/*    font-family: 'Lexend'; */
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	line-height: 60px;
	/* identical to box height */

	text-align: center;

	color: rgba(0, 0, 0, 0.87);
	margin: 150px 0px 0px 0px;
}

h1 {
	font-size: 48px;
}

.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	display: flex;
	justify-content: center;
	padding: 0;
	margin-right: 15%;
	margin-bottom: 1rem;
	margin-left: 15%;
	list-style: none;
}

.swiper-slide {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;

	background-clip: border-box;

	border-radius: 0.25rem;
	width: auto;

	border-radius: 40px 40px 40px 40px;
}

.swiper-container-3d {
	perspective: 1200px;
}

.swiper-container-3d .swiper-wrapper {
	transform-style: flat !important;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
	left: 10px;
	right: auto;
	display: none !important;
}

.swiper-button-next,
.swiper-button-prev {
	position: absolute;
	top: 50%;
	width: calc(var(--swiper-navigation-size) / 44 * 27);
	height: var(--swiper-navigation-size);
	margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
	z-index: 10;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--swiper-navigation-color, var(--swiper-theme-color));
	display: none !important;
}

.swiper-pagination-bullet-active {
	opacity: 1;
	background: linear-gradient(169.02deg, #b2ebf2 -58.33%, #d1c4e9 60.57%);
	border-radius: 10px;
	border: none;

	height: 12px;
	width: 33.426666259765625px;

	border-radius: 10px;
}

.swiper-pagination-bullet {
	height: 12px;
	width: 12.353333473205566px;

	display: inline-block;

	opacity: 1.2 !important;
	border: 1px solid #bfb5eb !important;
}

/* .test{ 
	grid-template-columns: ;
} */

.rightSideForm {
	background-image: url(../../assets/contactUsBlob.svg);
	background-size: cover;
	background-repeat: no-repeat;
	height: 100%;
	border-radius: 0px 60px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.rightSideFormAr {
	background-image: url(../../assets/contactUsBlobAr.svg);
	background-size: cover;
	background-repeat: no-repeat;
	height: 100%;
	border-radius: 60px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-left: 87px;
}

.contactInfoDiv {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 48px;
}

.contactInfoDivAr {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	gap: 48px;
}

.infoText {
	margin: 0;
	color: #222;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.infoItem {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 23px;
}

.infoItemAr {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	align-items: center;
	gap: 23px;
}

.infoItemAr p {
	direction: ltr;
	color: #222;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.infoTextAr {
	direction: ltr;
	color: #222;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.locationItem {
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-items: flex-start;
	padding-top: 14px;
}

.locationItemAr {
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-items: flex-end;
	padding-top: 14px;
}

@media (max-width: 500px) {

	.containerForm {
		margin-top: 10px;
		padding: 15px 10px;
		margin-bottom: 5px;
	}

	.rightSideForm {
		background: url(../../assets/formBlobMobile.png);
		background-size: cover;
		background-repeat: no-repeat;
		height: 100%;
		color: #222;
		/* Small/Medium */
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		border-radius: 0px 0px 30px;
		width: 100%;
		/* 21px */
	}

	.rightSideFormAr {
		background: url(../../assets/formBlobMobile.png);
		background-size: cover;
		background-repeat: no-repeat;
		height: 100%;
		color: #222;
		/* Small/Medium */
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		border-radius: 0px 0px 30px;
		width: 100%;
		padding-left: 10px;
		padding-top: 10px;
		/* 21px */
	}

	.contactInfoDiv {
		gap: 0px;
		padding: 0px 5px;
		margin-top: 65px;
	}

	.contactInfoDivAr {
		gap: 0px;
		padding: 0px 5px;
		margin-top: 65px;
	}

	.infoItem {
		display: inline-flex;
		align-items: center;
		gap: 16px;
		margin-bottom: 20px;
	}

	.infoItemAr {
		display: inline-flex;
		align-items: center;
		gap: 16px;
		margin-bottom: 20px;
	}

	.infoText {
		color: #222;
		/* Small/Medium */
		font-family: Lexend;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		/* 21px */
	}

	.infoItemAr p {
		color: #222;
		/* Small/Medium */
		font-family: Lexend;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		text-align: left;
		/* 21px */
	}
}

@media (max-width: 767px) {

	.card {
		position: relative;
		display: flex;
		flex-direction: column;
		min-width: 0;
		word-wrap: break-word;
		background-color: #fff;
		background-clip: border-box;
		margin: 60px 45px 0px 45px;
		border-radius: 0.25rem;
		width: auto;
		border: none;
		border-radius: 40px 40px 40px 40px;
		box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
		height: 300px;
		/* min-width: 250px; */
	}

	.card img {
		min-height: 100px !important;
		width: auto;
	}

	.card-title {
		text-align: center;
		font-family: Lexend;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 36px;
		margin-bottom: 15px;
		/* 300% */
	}

	.card-text {
		color: rgba(0, 0, 0, 0.87);
		text-align: center;
		font-family: Lexend;
		font-size: 12px !important;
		font-style: normal;
		font-weight: 300;
		line-height: 25px;
		/* 250% */
		padding: 10px !important;
	}
}

.card-header {
	margin-bottom: 20px
}


/* .card-body {
	margin-top: -40px !important;
} */

.containerForm {
	margin-top: 10px;
	padding: 15px 10px;
	margin-bottom: 5px;
}

.rightSideForm {
	/* background: url(../../assets/formBlobMobile.png); */
	background-size: cover;
	background-repeat: no-repeat;
	/* Add this line */
	height: 100%;
	color: #222;
	/* Small/Medium */
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	border-radius: 0px 0px 30px;
	width: 100%;
	/* 21px */
}


.rightSideFormAr {
	/* background: url(../../assets/formBlobMobile.png); */
	background-size: cover;
	background-repeat: no-repeat;
	height: 100%;
	color: #222;
	/* Small/Medium */
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	border-radius: 0px 0px 30px;
	width: 100%;
	padding-left: 10px;
	padding-top: 10px;
	/* 21px */
}

.contactInfoDiv {
	gap: 0px;
	padding: 0px 5px;
	margin-top: 65px;
}

.contactInfoDivAr {
	gap: 0px;
	padding: 0px 5px;
	margin-top: 65px;
}

.infoItem {
	display: inline-flex;
	align-items: center;
	gap: 16px;
	margin-bottom: 20px;
}

.infoItemAr {
	display: inline-flex;
	align-items: center;
	gap: 16px;
	margin-bottom: 20px;
}

.infoText {
	color: #222;
	/* Small/Medium */
	font-family: Lexend;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 21px */
}

.infoItemAr p {
	color: #222;
	/* Small/Medium */
	font-family: Lexend;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	text-align: left;
	/* 21px */
}