button .svg-container {
	margin-left: 107px;
	/* adjust this value as needed */
}

button#dropdownDefaultButton.btn.p-0.body-font.font-lexend.show .svg-container svg {
	transform: rotate(180deg);
}

button#dropdownDefaultButton.btn.p-0.body-font.font-lexend.show .svg-container svg path {
	fill: rgba(162, 155, 220, 0.8);
}

button {
	margin-bottom: 30px;
}

button#dropdownDefaultButton.btn.p-0.body-font.font-lexend.show {
	color: rgba(162, 155, 220, 0.8);
	margin-bottom: 60px;
}

.closeIconDiv {
	position: absolute;
	top: 80px;
	right: 80px;
	cursor: pointer;
}

.closeIconDivAr {
	position: absolute;
	top: 80px;
	left: 45px;
	cursor: pointer;

}