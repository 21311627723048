.text {
	/* left: 61px; */
	font-weight: 500;
	font-family: Poppins;
	color: #6f6f6f;
	/* width: 328px; */
	/* height: 73px; */
	text-align: 40px;
	/* margin-right: 53px; */
	/* margin-right: 44px; */
	padding-left: 17px;
	font-size: 16px;
}

.list-unstyled {
	padding-left: 0;
	list-style: none;
	line-height: 3;
}

.text-\[\#01babf\] {
	--tw-text-opacity: 1;
	color: rgb(1 186 191 / var(--tw-text-opacity));
	font-weight: 700;
}

.mt-4 {
	margin-top: -11.5rem !important;
}

.footerhr {
	height: 0px;
	left: 237px;
	top: 3860px;

	border: 1px solid #6f6f6f;
	transform: rotate(0deg);
}

.langs {
	margin-top: -167px;
	margin-left: 74px;
}

.bg {
	background-image: url(../../../public/img/Decoration.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-size: cover;
}

.footerhead {
	/*   font-family: 'Lexend'; */
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 150%;
	/* identical to box height, or 27px */

	font-feature-settings: 'salt' on, 'liga' off;

	color: #5b5b5b;
}

.footersub {
	/*  font-family: 'Lexend'; */
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 36px;
	/* or 22px */

	font-feature-settings: 'salt' on, 'liga' off;

	color: #767676;
}

a {
	text-decoration: none;
	color: black;
}

.atcopyright {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 144%;

	font-feature-settings: 'salt' on, 'liga' off;

	color: #767676;
}

.icons {
	text-align: initial;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 5px;
	padding-right: 5px;
	display: flex;
}

.icons a:hover {
	background-color: #ffffff;
	-webkit-border-radius: 50px;

	-moz-border-radius: 50px;

	border-radius: 50px;

	transition: all 0.2s ease;
	box-shadow: 0 0 0 0 #000, 0 0 0 0 #ffffff;
}

.icon-background {
	color: #c0ffc0;
}

.footer {
	margin-top: 50px;
}

@media screen and (max-width: 480px) {
	.bg {
		background-image: url(../../../public/img/Decoration1.png) !important;
		background-size: contain;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.footer {
		margin-top: 0px;
	}

	.icons a:hover {
		background-color: #ffffff;
		-webkit-border-radius: 50px;

		-moz-border-radius: 50px;

		border-radius: 50px;

		transition: all 0.2s ease;
		box-shadow: 0 0 0 0 #000, 0 0 0 0 #ffffff;
	}

	.social {
		padding: 9px !important;
	}
}

@media screen and (max-width: 768px) {
	.bg {
		background-image: url(../../../public/img/Decoration1.png) !important;
		background-size: contain;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.footer {
		margin-top: 0px;
	}

	.social {
		padding: 9px !important;
	}

	.icons a:hover {
		background-color: #ffffff;
		-webkit-border-radius: 50px;

		-moz-border-radius: 50px;

		border-radius: 50px;

		transition: all 0.2s ease;
		box-shadow: 0 0 0 0 #000, 0 0 0 0 #ffffff;
	}
}